;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"c4deac05023ff768d5f075bbde9417904f8e2a22"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import { replayIntegration } from "@sentry/nextjs";

import { initSentry } from "./domains/sentry";

initSentry({
  integrations: [
    replayIntegration({
      blockAllMedia: false,
      maskAllText: false,
    }),
  ],
  replaysOnErrorSampleRate: 1,
  replaysSessionSampleRate: 0,
});
